import './App.css';
import SignUpForm from './components/SignUpForm';


const App = () => {
  return (
    <div className='App'>
      <SignUpForm/>
    </div>
  );
}
 
export default App;